import React from 'react'
import DestinationSlider from './DestinationSlider'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { Image } from '../../Images'
import { makeStyles } from '@material-ui/core/styles'
import { useIntl } from 'gatsby-plugin-intl'
import { CustomButton } from '../../CustomButton'
import { Link as GatsbyLink, navigate } from 'gatsby'
import { Hidden } from '@material-ui/core'
import { graphql, PageProps, useStaticQuery } from 'gatsby'
import { formatCountries } from '../../../utils/helper'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#E0E5DE',
    justifyContent: 'center',
    padding: '6em 0em',
    width: '100%',
    display: 'flex',
    paddingBlock: theme.spacing(15),
    [theme.breakpoints.between('xs', 'sm')]: {
      padding: '4em 0em',
    },
  },
  into: {
    flexBasis: '90%',
    alignItems: 'center',
  },
  title: {
    fontFamily: `'Cormorant Garamond', serif`,
    // textTransform: 'uppercase',
    wordWrap: 'normal',
    lineHeight: '1',
    letterSpacing: '0em',
    fontSize: theme.typography.pxToRem(55),
    fontWeight: 400,
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(40),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      textAlign: 'center',
      marginTop: theme.spacing(0),
      fontSize: theme.typography.pxToRem(35),
    },
  },
  details: {
    lineHeight: '1.875',
    fontSize: '1rem',
    marginTop: theme.spacing(6),
    fontFamily: `'Gotham Light', serif`,
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(14),
      marginTop: '4rem',
      marginBottom: '4rem',
      paddingInline: '24px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      textAlign: 'center',
      marginTop: theme.spacing(4),
      marginBottom: '4rem',
      paddingInline: '24px',
      fontSize: '16px',
    },
  },
  zi1: {
    zIndex: 1,
    marginTop: '1em',
  },
  titleSection: {
    fontFamily: `'Cormorant Garamond', serif`,
    fontSize: theme.typography.pxToRem(18),
    textAlign: 'left',
    textTransform: 'uppercase',
    lineHeight: 1.6,
    textDecoration: 'none',
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(18),
      textAlign: 'center',
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(0),
      marginBottom: theme.spacing(1),
    },
    [theme.breakpoints.between('md', 'md')]: {
      textAlign: 'center',
      fontSize: theme.typography.pxToRem(18),
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(0),
    },
  },
  detailsSection: {
    fontFamily: `'Cormorant Garamond', serif`,
    fontSize: theme.typography.pxToRem(11),
    letterSpacing: '0em',
    lineHeight: 0.95,
    fontWeight: 550,
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(11),
      textAlign: 'center',
      paddingLeft: theme.spacing(0),
      paddingBottom: theme.spacing(1),
    },
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(11),
      textAlign: 'center',
      paddingLeft: theme.spacing(0),
      paddingBottom: theme.spacing(1),
    },
  },
  btnTxt: {
    fontFamily: `'Gotham Book', serif`,
    fontSize: theme.typography.pxToRem(13.8),
    [theme.breakpoints.between('xs', 'md')]: {
      fontSize: theme.typography.pxToRem(10),
    },
    color: 'inherit',
  },
  btnLink: {
    textDecoration: 'none',
    textTransform: 'initial',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'none',
      borderBottomColor: theme.palette.text.primary,
    },
  },
  containerImage: {
    zIndex: 0,
    // paddingTop: '-2em',
    paddingBottom: '4em',
    paddingLeft: '2em',
    [theme.breakpoints.between('md', 'md')]: {
      paddingLeft: '5em',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(4),
      paddingLeft: 'unset',
    },
  },
  containerSection: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(1),
    textTransform: 'none',
    textAlign: 'center',
    width: '100%',
    [theme.breakpoints.between('md', 'md')]: {
      paddingTop: theme.spacing(4),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingTop: theme.spacing(2),
    },
  },
}))

export const Destination = (props: any) => {
  const {
    allProperty: { nodes },
  } = useStaticQuery(graphql`
    query {
      allProperty {
        nodes {
          id
          ascenseur
          alarme
          airConditionne
          piscine
          bienEnCopro
          charges

          consommationEnergetique
          consommationEnergetiqueLabel
          emissionsGes
          emissionGesLabel
          energyCostMin
          energyCostMax
          coproEnProcedure
          nombreLotsCopro

          honorairesVente

          isOffmarket

          lifeStyle
          surfaceJardin
          surfaceTerrasse

          suiviPar {
            photo {
              portraitSquare
            }
            phone
            name
            email
          }
          photos {
            large
          }

          codePostal
          createdAt
          departement
          region
          descriptionAr
          descriptionEn
          descriptionEs
          descriptionFr
          descriptionPt
          descriptionRu
          descriptionZh
          nombreChambres
          nombrePieces
          nombreSallesDeBain
          pays

          prixAffichageFai
          prixConfidentiel
          prixLoyer

          propertyType
          propertyTypeDisplay
          propertyTypeSlug
          reference

          surfaceHabitable
          titreAr
          titreEn
          titreEs
          titreFr
          titrePt
          titreRu
          titreZh
          unitArea
          unitPrice

          ville
          villeFr
          villeEn
          villePt
          villeEs

          statutVente
          lat
          lng
          propertyTypeSlug
        }
      }
    }
  `)

  const classes = useStyles()
  const intl = useIntl()

  let groupedData = formatCountries(nodes.filter((el: any) => el?.statutVente === 'Vente'))

  if (props?.pays != "") {
    groupedData = groupedData.filter(el => el?.pays != props.pays)
  }


  return (
    <Grid container className={classes.root} justifyContent="center">
      <Grid container className={classes.into}>
        <Grid item xs={12} sm={12} md={5} lg={5} className={classes.zi1}>
          <Typography className={classes.title}>
            {intl.formatMessage({ id: 'new.Home_7' })}
          </Typography>
          <Hidden mdDown>
            <Typography className={classes.details}>
              {intl
                .formatMessage({ id: 'new.Home_8' })
                .split('\n')
                .map((s, index) => (
                  <div key={index}>{s}</div>
                ))}
            </Typography>
          </Hidden>
        </Grid>

        <Hidden smDown>
          <Grid item xs={12} sm={12} md={7} lg={7} className={classes.containerImage}>
            <Image src={'HP_DDR_Preview.png'} alt={'Preview ddr'} layout="FULL_WIDTH" />
          </Grid>
        </Hidden>
      </Grid>
      <Grid item xs={12} md={12} lg={12} className={classes.containerSection}>
        <DestinationSlider data={groupedData} />
      </Grid>
      <Hidden mdUp>
        <Typography className={classes.details}>
          {intl
            .formatMessage({ id: 'dreamDestination.detail' })
            .split('\n')
            .map((s, index) => (
              <div key={index}>{s}</div>
            ))}
        </Typography>
      </Hidden>
    </Grid>
  )
}

export default Destination
