import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import { Box, Grid, Hidden, useMediaQuery } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { AgencyDetails } from '../component/page_component/AgencyDetails'
import { BannerHomepage } from '../component/BannerHomepage'
import { Destination } from '../component/page_component/Destination'
import { Display } from '../component/page_component/Display'
import { Estimate } from '../component/page_component/Estimate'
import { FriendsOfHouse } from '../component/page_component/FriendsOfHouse'
import { Intro } from '../component/page_component/Intro'
import { Layout } from '../component/Layout'
import { LifeStyle } from '../component/page_component/LifeStyle'
import { Network } from '../component/page_component/Network'
import { Technology } from '../component/page_component/Technology'
import { graphql, PageProps, useStaticQuery } from 'gatsby'
import clsx from 'clsx'
import poster from '../../src/images/preview.png'
import videoMp4 from '../../src/images/video-hp.mp4'
import hreflangConfig from '../utils/hreflangConfig'
import { Helmet } from 'react-helmet'
import { Chasse } from '../component/page_component/chasse20'
import SellProperty from '../component/page_component/SellProperty'
import { Cinemagraph } from '../component/Cimenegraph'
import PreFooterMenu from '../component/page_component/contact/preFooterMenu'

const useStyles = makeStyles((theme) => ({
  intro: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(12),
    width: '100%',
    paddingBottom: theme.spacing(12),
    [theme.breakpoints.between('md', 'lg')]: {
      paddingTop: theme.spacing(6),
      paddingInline: '2rem',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(0),
    },
  },
  container: {
    textAlign: 'center',
  },
  orange: {
    backgroundColor: '#f1245A',
  },
  introMobile: {
    fontFamily: `'Cormorant Garamond', serif`,
    padding: '5em 3em',
    textAlign: 'center',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '28px',
  },
  section: {
    paddingBlock: '0px 110px',
    [theme.breakpoints.between('xs', 'sm')]: {},
  },
}))

const HOME_PAGE_QUERY = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
      }
    }
    allProperty {
      nodes {
        id
        ascenseur
        alarme
        airConditionne
        piscine
        bienEnCopro
        charges

        consommationEnergetique
        consommationEnergetiqueLabel
        emissionsGes
        emissionGesLabel
        energyCostMin
        energyCostMax
        coproEnProcedure
        nombreLotsCopro

        honorairesVente

        isOffmarket

        lifeStyle
        surfaceJardin
        surfaceTerrasse

        suiviPar {
          photo {
            portraitSquare
          }
          phone
          name
          email
        }
        photos {
          large
        }

        codePostal
        createdAt
        departement
        region
        descriptionAr
        descriptionEn
        descriptionEs
        descriptionFr
        descriptionPt
        descriptionRu
        descriptionZh
        nombreChambres
        nombrePieces
        nombreSallesDeBain
        pays

        prixAffichageFai
        prixConfidentiel
        prixLoyer

        propertyType
        propertyTypeDisplay
        propertyTypeSlug
        reference

        surfaceHabitable
        titreAr
        titreEn
        titreEs
        titreFr
        titrePt
        titreRu
        titreZh
        unitArea
        unitPrice

        ville
        villeFr
        villeEn
        villePt
        villeEs

        statutVente
        lat
        lng
        propertyTypeSlug
      }
    }

    allPropertyDistinct: allProperty {
      distinct(field: propertyType)
    }
       allPropertySlugDistinct: allProperty {
      distinct(field: propertyTypeSlug)
    }
  }
`

export const Home: React.FC<PageProps> = (props) => {
  const { location } = props
  const intl = useIntl()
  const classes = useStyles()

  const {
    site: {
      siteMetadata: { siteUrl },
    },

    allProperty: { nodes },
    allPropertyDistinct: { distinct },
    allPropertySlugDistinct
  } = useStaticQuery(HOME_PAGE_QUERY)

  const desiredOrder = [
    'Appartment',
    'House',
    'Villa',
    'HotelParticulier',
    'Castle',
    'Loft',
    'Chalet',
    'Mas',
  ]
  
  // Filter the list to include only items in desiredOrder and sort by desiredOrder
  const orderedList = distinct
    .filter((item: string) => desiredOrder.includes(item))
    .sort((a: string, b: string) => desiredOrder.indexOf(a) - desiredOrder.indexOf(b))

  const hreflangLinks = hreflangConfig['/fr/']

  const canonicalUrl = `${siteUrl}${location.pathname}`

  function getDistinctData(data: any[], key: string, category: string) {
    const countMap: Record<string, { ENV_A?: number; ALO_A?: number; VEN_B?: number }> = {}

    data.forEach((item: any) => {
      if (item[key]) {
        if (!countMap[item[key]]) {
          countMap[item[key]] = { ENV_A: 0, ALO_A: 0, VEN_B: 0 }
        }

        if (item.statutVente === 'Vente') {
          countMap[item[key]].ENV_A = (countMap[item[key]].ENV_A || 0) + 1
        } else if (item.statutVente === 'Location' || item.statutVente === 'LocSaison') {
          countMap[item[key]].ALO_A = (countMap[item[key]].ALO_A || 0) + 1
        } else if (item.statutVente === 'Vendu') {
          countMap[item[key]].VEN_B = (countMap[item[key]].VEN_B || 0) + 1
        }
      }
    })

    return Object.keys(countMap).map((value) => ({
      category,
      value,
      count: Object.values(countMap[value]).reduce((acc, curr) => acc + (curr || 0), 0), // Total count
      label: value,
      counts: countMap[value],
      tags: [value],
    }))
  }

  function getAllDistinctData(data: any[]) {
    return [
      ...getDistinctData(data, 'pays', 'COUNTRY'),
      ...getDistinctData(data, 'codePostal', 'ZIP_CODE'),
      ...getDistinctData(data, 'ville', 'TOWN'),
      ...getDistinctData(data, 'region', 'REGION'),
    ]
  }

  const getTimestamp = (value: any, key: string) => {
    const dateValue = value?.[key]
    return dateValue ? new Date(dateValue).getTime() : null
  }

  const odDisplay = nodes
    .filter(
      (item: any) =>
        !item.isOffmarket && item.statutVente === 'Vente' && item.prixLoyer > 1000000 && item.ville,
    )
    .sort((first: any, last: any) => {
      const date1 = getTimestamp(first, 'dateDebutMendat')
      const date2 = getTimestamp(last, 'dateDebutMendat')
      if (date1 === null) return 1 // Move null/undefined to end
      if (date2 === null) return -1
      return date2 - date1
    })
    .slice(0, 10)

  return (
    <Layout location={location} maxWidth={false} title={intl.formatMessage({ id: 'Kretz' })}>
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
        {hreflangLinks &&
          Object.keys(hreflangLinks).map((lang) => (
            <link key={lang} rel="alternate" hrefLang={lang} href={hreflangLinks[lang]} />
          ))}
        <meta
          name="description"
          content="Explorez les plus belles propriétés de luxe : villas, appartements, domaines privés. Notre expertise vous guide vers le bien immobilier de vos rêves."
        />
      </Helmet>
      <BannerHomepage
        distinctPropertyTypes={allPropertySlugDistinct?.distinct}
        locationList={getAllDistinctData(nodes || [])}
        videoSrc={{
          mp4: videoMp4,
          poster,
        }}
        innerStyle={{
          zIndex: '1100',
          position: 'relative',
        }}
      ></BannerHomepage>
      <div className={classes.intro}>
        <Intro
          title={intl.formatMessage({ id: 'new.Home' })}
          description={intl.formatMessage({ id: 'new.Home_2' })}
        />
      </div>
      <Display title={intl.formatMessage({ id: 'new.Home_5' })} edges={odDisplay} />
      <Hidden smUp>
        <Box className={classes.introMobile}>{intl.formatMessage({ id: 'new.Home_2' })}</Box>
      </Hidden>
      <Destination pays="" />
      <LifeStyle data={orderedList} />
      <Network />
      <Box className={classes.section}>
        <SellProperty />
      </Box>
      <Hidden mdDown>
        <div id={'estimate-section'}>
          <Estimate />
        </div>
      </Hidden>

      <AgencyDetails />
      {/* <Technology /> */}
      <Chasse />
      {/* <Grid container className={clsx(classes.container)}>
        <FriendsOfHouse showBtn />
      </Grid> */}
      <Cinemagraph flixelId="jtsueywkv381kalwb9l5" flixelMobileId="7od2xokpjecfjrq2jgsh" />
    </Layout>
  )
}

export default Home
