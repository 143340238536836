import React from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import Typography from '@material-ui/core/Typography'
import { CustomButton } from '../CustomButton'
import { Image } from '../Images'
import { Link as GatsbyLink } from 'gatsby'
import { makeStyles } from '@material-ui/core/styles'
import { useIntl } from 'gatsby-plugin-intl'

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(15),
    marginTop: theme.spacing(15),
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.between('xs', 'md')]: {
      marginBottom: theme.spacing(0),
      marginTop: theme.spacing(6),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: theme.spacing(0),
      padding: '4em 2em 0em 2em',
      marginBottom: theme.spacing(0),
    },
  },
  inner: {
    flexBasis: '100%',
    marginInline: '80px',
    justifyContent: 'space-between',
    [theme.breakpoints.between('sm', 'md')]: {
      alignItem: 'center',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      flexBasis: '100%',
      marginInline: '0',
    },
  },
  imgContainer: {
    display: 'flex',
  },
  texteContainer: {},
  img: {
    [theme.breakpoints.between('xs', 'sm')]: {
      height: '100%',
    },
  },
  title: {
    fontFamily: `'Cormorant Garamond', serif`,
    lineHeight: '58px',
    fontSize: '52px',
    fontWeight: 400,
    marginTop: theme.spacing(4),
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(40),
      textAlign: 'center',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(35),
      textAlign: 'center',
    },
  },
  detail: {
    lineHeight: '24px',
    fontSize: '14px',
    fontFamily: `'Gotham Light', serif`,
    fontWeight: 325,
    marginBlock: theme.spacing(6),
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(14),
      textAlign: 'center',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: theme.spacing(4),
      fontSize: theme.typography.pxToRem(13),
      fontFamily: `'Gotham Book', serif`,
      textAlign: 'center',
    },
  },
  btnTxt: {
    fontFamily: `'Gotham Book', serif`,
    fontSize: theme.typography.pxToRem(13.8),
    [theme.breakpoints.between('xs', 'md')]: {
      fontSize: theme.typography.pxToRem(10),
    },
    color: 'inherit',
  },
  btnLink: {
    textDecoration: 'none',
    textTransform: 'initial',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'none',
      borderBottomColor: theme.palette.text.primary,
    },
  },
  btndiv: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(0),
    [theme.breakpoints.between('md', 'md')]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(0),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(0),
    },
  },
  textContainer: {
    [theme.breakpoints.between('sm', 'md')]: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
}))

export const Network = () => {
  const classes = useStyles()
  const intl = useIntl()

  return (
    <Box className={classes.root}>
      <Grid container className={classes.inner}>
        <Grid item sm={12} md={6} className={classes.imgContainer}>
          <Grid container md={12}>
            <Grid item md={11} xs={12}>
              <Image
                src="HP_Network_bbny.jpg"
                className={classes.img}
                alt="Broooklyn bridge"
                layout="CONSTRAINED"
                style={{
                  aspectRatio: 'calc(616/467)',
                  width: '100%',
                  zIndex: 0,
                  borderRadius: '8px',
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6} sm={12} className={classes.textContainer}>
          <Grid item sm={12} md={10} className={classes.title}>
            {intl
              .formatMessage({ id: 'new.Home_10' })
              .split('\n')
              .map((str) => (
                <div>{str}</div>
              ))}
          </Grid>
          <Grid item sm={12} md={10} className={classes.detail}>
            {intl
              .formatMessage({ id: 'new.Home_11' })
              .split('\n')
              .map((s, index) => (
                <div key={index} style={{ marginBottom: 0 }}>
                  {s}
                </div>
              ))}
          </Grid>
          {/* <Grid item sm={12} md={6} className={classes.btndiv}>
            <CustomButton>
              <GatsbyLink to={`/${intl.locale}/agency`} color="inherit" className={classes.btnLink}>
                <Typography className={classes.btnTxt}>
                  {intl.formatMessage({ id: 'network.btnText' })}
                </Typography>
              </GatsbyLink>
            </CustomButton>
          </Grid> */}
        </Grid>
      </Grid>
    </Box>
  )
}

export default Network
