import React, { useEffect, useState } from 'react'
import { Hidden } from '@material-ui/core'
import styled from 'styled-components'
import { Image } from '../component/Images'
import { getCookieValue } from '../utils/cookies'

const CinemagraphWrapper = styled.div`
  position: relative;
  --padding: 50%;
  @media (max-width: 959px) {
    --padding: 106%;
  }
  padding-bottom: var(--padding);
  position: relative;
  height: 0;
  width: 100%;
  background: #1D1D1B;

  iframe {
    border: none;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    margin-top: -28px;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
`

export function Cinemagraph({flixelId, flixelMobileId, ...props}: any) {

  const srcGif = "https://media.flixel.com/cinemagraph/" + flixelId + "?hd=true&dnt=1"
  const srcGifMobile = "https://media.flixel.com/cinemagraph/" + flixelMobileId + "?hd=false&dnt=1"
  const srcPicture = flixelId + ".thumbnail.jpg"
  const srcPictureMobile = flixelMobileId + ".thumbnail.jpg"

  const cookieName = 'kretz-cookie-consent'
  const [thirdPartyCookie, setCookieValue] = useState({})

  useEffect(() => {
    setCookieValue(getCookieValue(cookieName))
  }, [])

  return (
    <CinemagraphWrapper {...props}>
    <Hidden smDown>
      {thirdPartyCookie && thirdPartyCookie["flixel-third-party-cookies-allowed"] == '1' ?
        <iframe title="iframe" src={srcGif} allow="autoplay" frameBorder="0" /> :
        <Image src={srcPicture} alt={'Image flixel animée : ' + flixelId} layout="CONSTRAINED" />
      }
    </Hidden>
      <Hidden mdUp>
        {thirdPartyCookie && thirdPartyCookie["flixel-third-party-cookies-allowed"] == '1' ?
          <iframe title="iframe-mobile" src={srcGifMobile} allow="autoplay" frameBorder="0" /> :
          <Image src={srcPictureMobile} alt={'Image flixel animée : ' + flixelMobileId} layout="CONSTRAINED" />
        }
      </Hidden>
    </CinemagraphWrapper>
  )
}
