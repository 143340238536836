import React from 'react'
import Carousel, { ButtonGroupProps } from 'react-multi-carousel'
import Grid from '@material-ui/core/Grid'
import theme from '../../../Theme/theme'
import { DESTINATIONS } from '../../../utils/constants'
import { SliderImgDestination } from '../../SliderImgDestination'
import { makeStyles } from '@material-ui/core/styles'
import { useIntl } from 'gatsby-plugin-intl'
import { Box, useMediaQuery } from '@material-ui/core'
import CustomButtonGroupAsArrows from './CustomButtonGroupArrows'
import DestinationSliderOutsideButton from './DestinationSliderOutsideButton'

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 2500 },
    items: 6,
    partialVisibilityGutter: 100,
  },
  desktop: {
    breakpoint: { max: 2500, min: 1480 },
    items: 5,
    partialVisibilityGutter: 100,
  },
  smallDesktop: {
    breakpoint: { max: 1480, min: 1080 },
    items: 4,
    partialVisibilityGutter: 100,
  },
  tablet: {
    breakpoint: { max: 1080, min: 780 },
    items: 3,
  },
  largeMobile: {
    breakpoint: { max: 780, min: 580 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 580, min: 0 },
    items: 1,
  },
}

const useStyles = makeStyles((theme) => ({
  img: {
    paddingRight: '2rem',
    [theme.breakpoints.between('md', 'md')]: {
      paddingRight: '1.5rem',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingRight: '1rem',
    },
  },
}))

export const DestinationSlider = ({ data }) => {
  const classes = useStyles()
  const intl = useIntl()
  const matches = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Grid>
      <Carousel
        responsive={responsive}
        additionalTransfrom={0}
        autoPlaySpeed={5000}
        containerClass="container"
        focusOnSelect={false}
        infinite
        arrows={false}
        centerMode
        keyBoardControl
        // 
        renderButtonGroupOutside={true}
        renderDotsOutside={false}
        customButtonGroup={<DestinationSliderOutsideButton />}
      >
        {data.map((eachDestination: any) => (
          <Box className={classes.img}>
            <SliderImgDestination
              text={eachDestination?.pays}
              subtext={eachDestination?.pays}
              to={`/${intl.locale}/${eachDestination?.pays?.toLowerCase()}`}
              src={DESTINATIONS[eachDestination?.pays?.toLowerCase()]?.sliderImg || ''}
              alt={'Image link in a carousel for ' + eachDestination}
            />
          </Box>
        ))}
      </Carousel>
    </Grid>
  )
}

export default DestinationSlider
