import { Hidden, Typography, useMediaQuery } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { Link as GatsbyLink } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import React from 'react'
import { CustomButton } from '../CustomButton'
import { Image } from '../Images'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import theme from '../../Theme/theme'

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: `'Cormorant Garamond', serif`,
    backgroundColor: '#E0E5DE',
    padding: '120px 80px',
    textAlign: 'center',
    [theme.breakpoints.between('xs', 'sm')]: {
      padding: '4em 2em',
    },
  },
  agencyDetailsTitle: {
    fontFamily: `'Cormorant Garamond', serif`,
    textTransform: 'uppercase',
    lineHeight: '1',
    letterSpacing: '0em',
    fontSize: '80px',
    fontWeight: 400,
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: '48px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: theme.spacing(0),
      fontSize: '48px',
    },
  },
  sub_title: {
    fontFamily: `'Gotham book', serif`,
    fontSize: '14px',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(5),
    fontWeight: 700,
    textTransform: 'uppercase',
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: '16px',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '16px',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  detail: {
    fontFamily: `'Gotham Light', serif`,
    fontWeight: 325,
    fontSize: theme.typography.pxToRem(14),
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3),
    flexBasis: '50%',
    lineHeight: '24px',
    [theme.breakpoints.between('md', 'md')]: {
      flexBasis: '80%',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      flexBasis: '85%',
    },
  },
  images: {
    paddingInline: '20px',
    [theme.breakpoints.down('sm')]: {
      paddingInline: '0rem',
    },
  },
  imageOverlay: {
    position: 'relative',
    overflow: 'hidden',
    '&::after': {
      content: "''",
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.0) 0%, rgba(0, 0, 0, 0) 80%)',
      pointerEvents: 'none',
      zIndex: 1,
    },
    '& img': {
      display: 'block',
      width: '100%',
      height: 'auto',
    },
  },
  topImg: {
    width: '18em',
    [theme.breakpoints.between('md', 'md')]: {
      width: '14em',
      marginTop: '-4rem',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      width: '10em',
      marginTop: '-4rem',
    },
  },
  episode: {
    paddingBottom: '4rem',
  },
  img: {},
  episodeTitle: {
    fontFamily: `'Cormorant Garamond', serif`,
    fontSize: theme.typography.pxToRem(34),
    fontWeight: 'bold',
    padding: '1.3rem 0',
    textAlign: 'left',
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(25),
      padding: '.8rem 0',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(20),
      padding: '.3rem 0',
    },
  },
  btnTxt: {
    fontFamily: `'Gotham Book', serif`,
    textTransform: 'uppercase',
    fontSize: theme.typography.pxToRem(13.8),
    [theme.breakpoints.between('xs', 'md')]: {
      fontSize: theme.typography.pxToRem(10),
    },
    color: 'inherit',
  },
  btnLink: {
    textDecoration: 'none',
    textTransform: 'initial',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'none',
      borderBottomColor: theme.palette.text.primary,
    },
  },
  btnDiv: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.between('md', 'md')]: {
      marginTop: theme.spacing(3),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: theme.spacing(0),
    },
  },
}))

interface AgencyDetailsProps {
  showSildeShow?: boolean
  showSubtitle?: boolean
  showEpisode?: boolean
}

export const AgencyDetails: React.FC<AgencyDetailsProps> = (props) => {
  const { showSildeShow = true, showSubtitle = true, showEpisode = false } = props
  const classes = useStyles()
  const intl = useIntl()
  const isSm = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Grid container justifyContent="center" className={classes.root}>
      <Grid item justifyContent="center" sm={12}>
        <Grid item sm={12} className={classes.agencyDetailsTitle}>
          {intl.formatMessage({ id: 'new.Agence' })}
        </Grid>
        {showSubtitle && (
          <Grid item sm={12} className={classes.sub_title}>
            {intl.formatMessage({ id: 'new.Home_15' })}
          </Grid>
        )}
        <Hidden mdUp>
          <Grid item md={12} className={clsx(classes.images, classes.imageOverlay)}>
            <Image
              src="L_Agence3.jpg"
              alt="Broooklyn bridge"
              layout="CONSTRAINED"
              style={{
                aspectRatio: 'calc(345/240)',
                width: '100%',
                zIndex: 0,
                borderRadius: '8px',
              }}
            />
          </Grid>
        </Hidden>
        <Hidden mdDown>
          {showSildeShow && (
            <Grid container direction="row" justifyContent="space-between">
              <Grid item md={4} className={clsx(classes.images, classes.imageOverlay)}>
                <Image
                  src="L_Agence3.jpg"
                  className={classes.img}
                  alt="Broooklyn bridge"
                  // layout="CONSTRAINED"
                  style={{
                    aspectRatio: 'calc(397/268)',
                    width: '100%',
                    zIndex: 0,
                    borderRadius: '8px',
                  }}
                />
              </Grid>
              <Grid item md={4} className={clsx(classes.images, classes.imageOverlay)}>
                <Image
                  src="agence2.png"
                  className={classes.img}
                  alt="Broooklyn bridge"
                  // layout="CONSTRAINED"
                  style={{
                    aspectRatio: 'calc(397/268)',
                    width: '100%',
                    zIndex: 0,
                    borderRadius: '8px',
                  }}
                />
              </Grid>
              <Grid item md={4} className={clsx(classes.images, classes.imageOverlay)}>
                <Image
                  src="agence3.png"
                  className={classes.img}
                  alt="Broooklyn bridge"
                  // layout="CONSTRAINED"
                  style={{
                    aspectRatio: 'calc(397/268)',
                    width: '100%',
                    zIndex: 0,
                    borderRadius: '8px',
                  }}
                />
              </Grid>
            </Grid>
          )}
        </Hidden>
        <Grid container justifyContent="center">
          <Grid item className={classes.detail}>
            {intl.formatMessage({ id: 'new.Home_16' })}
          </Grid>
        </Grid>
        {showEpisode && (
          <>
            <Grid item xs={12} className={classes.episodeTitle}>
              <Box>{intl.formatMessage({ id: 'episode' })}</Box>
            </Grid>
            <Grid item xs={12} className={classes.episode}>
              <Image src="a4.png" alt="buy_banner" layout="FULL_WIDTH" />
            </Grid>
          </>
        )}
        <Grid item xs={12} className={classes.btnDiv}>
          <CustomButton
            width={isSm ? '100%' : ''}
            isDarkBackground={true}
            displayIcon={isSm ? false : true}
            iconPosition="end"
            icon={() => <ArrowForwardIcon />}
          >

            <GatsbyLink
              target={'_blank'}
              to={`/${intl.locale}/agency`}
              color="inherit"
              className={classes.btnLink}
            >
              <Typography className={classes.btnTxt}>
                {intl.formatMessage({ id: 'agency.btnText' })}
              </Typography>
            </GatsbyLink>
          </CustomButton>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AgencyDetails
