import React, { useRef } from 'react'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import { makeStyles } from '@material-ui/core/styles'

import { useIntl } from 'gatsby-plugin-intl'
import { CustomButton } from '../CustomButton'
import { Image } from '../Images'
import styled from 'styled-components'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.between('xs', 'md')]: {},
    [theme.breakpoints.between('xs', 'sm')]: {
      padding: '4em 2em',
    },
  },
  innerDiv: {
    flexBasis: '90%',
    justifyContent: 'space-between',
    [theme.breakpoints.between('xs', 'sm')]: {
      flexBasis: 'unset',
      display: 'flex',
      flexDirection: 'row-reverse',
    },
  },
  reverseOrderFirst: {
    order: 1, // Par défaut : deuxième élément
    [theme.breakpoints.down('sm')]: {
      order: 2, // Inverser sur les petits écrans
    },
  },
  reverseOrderSecond: {
    order: 2, // Par défaut : premier élément
    [theme.breakpoints.down('sm')]: {
      order: 1, // Inverser sur les petits écrans
    },
  },
  img: {
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: theme.spacing(0),
    },
  },
  gimg: {
    textAlign: 'right',
    [theme.breakpoints.between('xs', 'sm')]: {
      textAlign: 'center',
    },
  },
  title: {
    fontFamily: `'Cormorant Garamond', serif`,
    lineHeight: '58px',
    letterSpacing: '0em',
    fontSize: theme.typography.pxToRem(52),
    fontWeight: 400,
    textAlign: 'left',
    marginTop: theme.spacing(4),
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(40),
      textAlign: 'center',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      textAlign: 'center',
      marginTop: theme.spacing(1),
      fontSize: theme.typography.pxToRem(35),
    },
  },
  detail: {
    fontFamily: "'Gotham Light', serif",
    lineHeight: '24px',
    fontSize: '14px',
    fontWeight: 235,
    marginTop: theme.spacing(1.5),
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(14),
      textAlign: 'center',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      textAlign: 'center',
      marginTop: theme.spacing(3.5),
      fontSize: theme.typography.pxToRem(13),
      fontFamily: `'Gotham Book', serif`,
    },
  },
  btnTxt: {
    fontFamily: `'Gotham Book', serif`,
    fontSize: theme.typography.pxToRem(13.8),
    [theme.breakpoints.between('xs', 'md')]: {
      fontSize: theme.typography.pxToRem(10),
    },
    color: 'inherit',
  },
  btnLink: {
    textDecoration: 'none',
    textTransform: 'initial',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'none',
      borderBottomColor: theme.palette.text.primary,
    },
  },
  btnDiv: {
    marginTop: theme.spacing(15.6),
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: theme.spacing(4),
    },
  },
}))

export const SellProperty = () => {
  const classes = useStyles()
  const intl = useIntl()
  const ref: any = useRef()

  return (
    <Grid container className={classes.root}>
      <Grid container spacing={2} className={classes.innerDiv}>
        {/* Première colonne */}
        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          justifyContent="flex-end"
          className={classes.reverseOrderFirst}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Grid className={classes.title}>
            <span>{intl.formatMessage({ id: 'new.Home_12' })}</span>
          </Grid>
          <Grid container spacing={2} className={classes.detail}>
            <Grid item md={12}>
              {intl.formatMessage({ id: 'new.Home_13' })}
            </Grid>
          </Grid>
          <Hidden mdUp>
            <Grid container xs={12} style={{ marginTop: '3rem' }}>
              <CustomButton
                width={'100%'}
                darkBoarder={true}
                isWhiteBackground
                onClick={() => {
                  window.location.href = `/${intl.locale}/sell`
                }}
              >
                {intl.formatMessage({ id: 'new.Home_4' })}
              </CustomButton>
            </Grid>
          </Hidden>
        </Grid>

        {/* Deuxième colonne */}
        <Grid item lg={6} md={6} sm={12} xs={12} className={classes.reverseOrderSecond}>
          <Grid container>
            <Grid item md={12}>
              <Image
                src="DSC00844.jpg"
                className={classes.img}
                alt="Sell property"
                layout="CONSTRAINED"
                style={{
                  aspectRatio: 'calc(616/346)',
                  width: '100%',
                  zIndex: 0,
                  borderRadius: '8px',
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SellProperty
