import React, { useContext, useState } from 'react'
import { Button, TextField, Tabs, Tab, Box, Hidden, useMediaQuery } from '@material-ui/core'
import { useIntl } from 'gatsby-plugin-intl'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { MoreCriteria } from '../filterBar/moreCriteria'
import { CustomButton } from '../CustomButton'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { useTheme } from '@material-ui/core/styles'
import AddressAutoComplete from '../MultiSelect/AddressAutoComplete'
import { capitalize, revertFormatNumber } from '../../utils/helper'
import CountryAddressAutoComplete from '../MultiSelect/CountryAddressAutoComplete'
import { SearchContext } from '../../contexts/searchContext'
import { navigate } from 'gatsby'
import { NumericFormat } from 'react-number-format'

const useStyles = makeStyles((theme) => ({
  searchContainer: {
    background:
      'linear-gradient(99.9deg, rgba(255, 255, 255, 0.8) -0.09%, rgba(255, 255, 255, 0.4) 175.97%)',
    backdropFilter: 'blur(12px)',
    boxShadow: '0px 1px 24px 0px rgba(0, 0, 0, 0.1)',
    width: '100%',
    maxWidth: '400px',
    padding: '20px',
    borderRadius: '0px 10px 10px 10px',
    position: 'relative',
    margin: '0 auto',
    fontFamily: "'Gotham Book', serif",
    [theme.breakpoints.down('sm')]: {
      padding: '15px',
      maxWidth: '100%',
      borderRadius: '10px',
    },
  },

  tabsRoot: {
    position: 'absolute',
    top: '-48px',
    left: '0',
    right: '0',
    display: 'flex',
    justifyContent: 'space-between',
    zIndex: 2,
    [theme.breakpoints.down('sm')]: {
      top: '-48px',
    },
  },

  tabs: {
    background: 'rgba(255, 255, 255, 0.8)',
    borderBottom: 'unset',
    borderRadius: '8px 8px 0 0',
    marginBottom: '-2px',
    fontFamily: "'Gotham Book', serif",
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    },
    '& .MuiTab-root': {
      background: 'rgba(255, 255, 255, 0.2)',
      fontFamily: "'Gotham Book', serif",
      border: '0px',
      [theme.breakpoints.down('sm')]: {
        paddingInline: '2rem',
      },
    },
    '& .MuiTab-root.Mui-selected': {
      background: 'rgba(255, 255, 255, 0.8)',
      fontWeight: 'bold',
    },
    '& .MuiTabs-indicator': {
      display: 'none',
    },
  },

  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
    [theme.breakpoints.down('sm')]: {
      gap: '0',
    },
  },

  inputGroup: {
    display: 'flex',
    gap: '1rem',
    paddingBlock: '0.4rem',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      gap: '20px',
      alignItems: 'flex-end',
    },
  },

  inputField: {
    '& .MuiOutlinedInput-root': {
      border: 'none',
      borderBottom: '1px solid #1D1D1B',
      padding: 0,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderBottom: '1px solid #1D1D1B',
    },
    '& .MuiInputBase-input': {
      fontSize: '14px',
      padding: '0.5rem',
    },
    '& ::placeholder': {
      fontSize: '14px',
    },
  },
  searchBtnMobile: {
    backgroundColor: '#1D1D1B',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#1D1D1B!important',
      color: '#FFF !important',
    },
  },

  buttonGroup: {
    display: 'flex',
    gap: '10px',
    marginTop: '15px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },

  mobileTitleContainer: {
    backgroundColor: '#fff',
    padding: '1rem 2rem',
    fontFamily: `'Cormorant Garamond', serif`,
    color: '#000',
    fontSize: '20px',
    textAlign: 'center',
    borderRadius: '4px',
    marginBottom: '10px',
    fontWeight: 500,
  },
  inputPlaceholder: {
    '&::placeholder': {
      fontFamily: "'Gotham Light', serif",
      fontSize: '12px',
      fontWeight: 325,
      lineHeight: '14.4px',
      textAlign: 'left',
      textUnderlinePosition: 'from-font',
      textDecorationSkipInk: 'none',
      color: '#818181 !important',
    },
  },
}))

export const SearchBox = ({ locationList, setSearch, searchState,distinctPropertyTypes }) => {
  const classes = useStyles()
  const intl = useIntl()
  const theme = useTheme()
  const [activeTab, setActiveTab] = useState(0)
  const isSm = useMediaQuery(theme.breakpoints.down('sm'))

  const handleTabChange = (event: any, newValue: any) => {
    setActiveTab(newValue)
    let value = 'buy'
    if (newValue === 1) {
      value = 'rent'
    }
    setSearch({ name: 'transactionType', value: value })
  }

  const handleChange = ({ target: { name, value } }: { target: { name: string; value: any } }) => {
    setSearch({ name, value: value ? revertFormatNumber(value) : '' })
  }

  return (
    <Box>
      <Hidden mdUp>
        <Box className={classes.mobileTitleContainer}>
          {intl.formatMessage({
            id: 'new.findYourDreamProperty',
          })}
        </Box>
      </Hidden>
      <Box className={classes.searchContainer}>
        {/* Tabs */}
        <Hidden smDown>
          <Box className={classes.tabsRoot}>
            <Tabs value={activeTab} onChange={handleTabChange} className={classes.tabs}>
              <Tab label={intl.formatMessage({ id: 'new.Recherche' })} />
              <Tab label={intl.formatMessage({ id: 'new.Recherche_2' })} />
            </Tabs>
          </Box>
        </Hidden>
        {/* Search Form */}
        <form className={classes.form}>
          <Hidden smDown>
            <div className={classes.inputGroup}>
              <CountryAddressAutoComplete
                placeholder={intl.formatMessage({
                  id: 'autocomplete.COUNTRY',
                })}
                setSearch={setSearch}
                searchStateLocation={searchState.country}
                searchStateTransactionType={searchState.transactionType}
                locationList={locationList as any[]}
              // disableCountryFilter={disableCountryFilter}
              />

              {/* <TextField
              placeholder={intl.formatMessage({ id: 'search.country' })}
              className={clsx(classes.inputField)}
              style={{ paddingBlock: '0.8rem' }}
              InputProps={{
                classes: {
                  input: classes.inputPlaceholder,
                },
              }}
            /> */}
            </div>
          </Hidden>
          <div className={classes.inputGroup}>
            <AddressAutoComplete
              setSearch={setSearch}
              searchStateLocation={searchState.location}
              searchStateTransactionType={searchState.transactionType}
              placeholder={`${capitalize(intl.formatMessage({ id: 'city' }))}, ${intl
                .formatMessage({ id: 'postalCode' })
                .toLowerCase()}, ${intl.formatMessage({ id: 'region' }).toLowerCase()}...`}
              locationList={locationList}
            />
          </div>
          <div className={classes.inputGroup}>
            <NumericFormat
              value={searchState.maxBudget}
              placeholder={intl.formatMessage({ id: isSm ? 'search.budget' : 'new.Recherche_6' })}
              name="maxBudget"
              onValueChange={(values) =>
                handleChange({ target: { name: 'maxBudget', value: values.value } })
              }
              thousandSeparator=" "
              decimalSeparator=","
              allowNegative={false}
              customInput={TextField}
              InputProps={{
                endAdornment: <span>€</span>,
                classes: {
                  input: classes.inputPlaceholder,
                },
              }}
            />
            <NumericFormat
              value={searchState.minSurface}
              placeholder={intl.formatMessage({
                id: isSm ? 'search.surface' : 'new.Recherche_7',
              })}
              name="minSurface"
              onValueChange={(values) =>
                handleChange({ target: { name: 'minSurface', value: values.value } })
              }
              thousandSeparator=" "
              decimalSeparator=","
              allowNegative={false}
              customInput={TextField}
              InputProps={{
                endAdornment: <span>m²</span>,
                classes: {
                  input: classes.inputPlaceholder,
                },
              }}
            />
            <Hidden mdUp>
              <Button
                onClick={() => navigate(`/${intl.locale}/search`)}
                variant="contained"
                color="secondary"
                size="small"
                className={classes.searchBtnMobile}
                style={{
                  width: '36px',
                  height: '36px',
                  padding: 'auto',
                  minWidth: '36px',
                }}
              >
                <ArrowForwardIcon style={{ fontSize: '20px' }} />{' '}
              </Button>
            </Hidden>
          </div>
          <Hidden smDown>
            <div className={classes.buttonGroup}>
              <MoreCriteria locationList={locationList}
               distinctPropertyTypes={distinctPropertyTypes}
                searchState={searchState} />
              <CustomButton
                href={`/${intl.locale}/search`}
                isDarkBackground={true}
                icon={() => <ArrowForwardIcon />}
                iconPosition="end"
                displayIcon={true}
              >
                {intl.formatMessage({ id: 'new.Recherche_9' })}
              </CustomButton>
            </div>
          </Hidden>
        </form>
      </Box>
    </Box>
  )
}
