import React, { useRef } from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

import { useIntl } from 'gatsby-plugin-intl'
import { CustomButton } from '../CustomButton'
import { Image } from '../Images'
import styled from 'styled-components'
import { Link as GatsbyLink } from 'gatsby'

import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: '6em 2em',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.between('xs', 'md')]: {},
    [theme.breakpoints.between('xs', 'sm')]: {
      padding: '4em 2em',
    },
  },
  innerDiv: {
    flexBasis: '80%',
    justifyContent: 'space-between',
    [theme.breakpoints.between('xs', 'md')]: {
      flexBasis: 'unset',
    },
  },
  reverseOrderFirst: {
    order: 1,
    [theme.breakpoints.down('sm')]: {
      order: 2,
    },
  },
  reverseOrderSecond: {
    order: 2,
    [theme.breakpoints.down('sm')]: {
      order: 1,
    },
  },
  img: {
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: theme.spacing(0),
    },
  },
  gimg: {
    textAlign: 'right',
    [theme.breakpoints.between('xs', 'sm')]: {
      textAlign: 'center',
    },
  },
  title: {
    fontFamily: `'Cormorant Garamond', serif`,
    lineHeight: '58px',
    letterSpacing: '0em',
    fontSize: theme.typography.pxToRem(52),
    fontWeight: 400,
    marginTop: theme.spacing(4),
    [theme.breakpoints.between('md', 'md')]: {
      textAlign: 'center',
      fontSize: theme.typography.pxToRem(40),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      textAlign: 'center',
      marginTop: theme.spacing(1),
      fontSize: theme.typography.pxToRem(35),
    },
  },
  detail: {
    fontFamily: "'Gotham Light', serif",
    lineHeight: '24px',
    fontSize: '14px',
    fontWeight: 235,
    marginTop: theme.spacing(1.5),
    [theme.breakpoints.between('md', 'md')]: {
      textAlign: 'center',
      fontSize: theme.typography.pxToRem(14),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      textAlign: 'center',
      marginTop: theme.spacing(3.5),
      fontSize: theme.typography.pxToRem(13),
      fontFamily: `'Gotham Book', serif`,
    },
  },
  btnTxt: {
    fontFamily: `'Gotham Medium', serif`,
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bolder',
    fontSize: theme.typography.pxToRem(13),
    [theme.breakpoints.between('xs', 'md')]: {
      fontSize: theme.typography.pxToRem(14),
    },
    color: 'inherit',
  },
  btnLink: {
    textDecoration: 'none',
    textTransform: 'initial',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'none',
      borderBottomColor: theme.palette.text.primary,
    },
  },
  btnDiv: {
    marginTop: theme.spacing(15.6),
    [theme.breakpoints.between('xs', 'sm')]: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(4),
    },
  },
}))

export const Chasse = () => {
  const classes = useStyles()
  const intl = useIntl()
  const ref: any = useRef()

  return (
    <Grid container className={classes.root}>
      <Grid container spacing={3} className={classes.innerDiv}>
        <Grid item lg={6} md={12} justifyContent="flex-end" className={classes.reverseOrderFirst}>
          <Grid className={classes.title}>
            <span>{intl.formatMessage({ id: 'new.Home_18' })}</span>
          </Grid>
          <Grid container spacing={3} className={classes.detail}>
            <Grid item md={6} lg={12}>
              {intl.formatMessage({ id: 'new.Home_19' })}
            </Grid>
          </Grid>
          <Grid item xs={12} md={8} className={classes.btnDiv}>
            <GatsbyLink
              target="_blank"
              to={`https://www.realestate-kretz.com/projetachat/chassekretz`}
              color="inherit"
              className={classes.btnLink}
            >
              <Typography className={classes.btnTxt}>
                {intl.formatMessage({ id: 'new.Home_20' })}&nbsp;&nbsp; <ArrowForwardIcon />
              </Typography>
            </GatsbyLink>
          </Grid>
        </Grid>
        <Grid item lg={5} xs={12} className={classes.reverseOrderSecond}>
          <Image
            src="DSC00695.jpg"
            className={classes.img}
            alt="Chasse"
            layout="CONSTRAINED"
            style={{ height: '100%', width: '100%', zIndex: 0, borderRadius: '8px' }}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Chasse
