import React from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { Link as GatsbyLink } from 'gatsby'

import LogoSvg from '../../icons/icon.svg'
import { KretzIconCircle } from '../../icons/KretzIconCircle'
import { CustomButton } from '../CustomButton'
import Hidden from '@material-ui/core/Hidden'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import { useIntl } from 'gatsby-plugin-intl'
import { useTheme } from '@material-ui/core/styles'
import { useMediaQuery } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: `'Cormorant Garamond', serif`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'left',
    flexBasis: '80%',
    [theme.breakpoints.between('md', 'md')]: {
      fontFamily: `'Cormorant Garamond', serif`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      position: 'relative',
      flexBasis: '100%',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontFamily: `'Cormorant Garamond', serif`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      position: 'relative',
      padding: '0 2em',
      paddingBottom: '4em',
      flexBasis: '100%',
    },
  },
  titleContainer: {
    padding: '2rem',
    [theme.breakpoints.down('md')]: {
      padding: '0rem',
    },
  },
  title: {
    fontFamily: `'Cormorant Garamond', serif`,
    textAlign: 'center',
    // textTransform: 'uppercase',
    lineHeight: '1',
    letterSpacing: '0em',
    width: '100%',
    fontSize: theme.typography.pxToRem(40),
    fontWeight: 400,
    marginTop: theme.spacing(4),
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(24),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: theme.spacing(2),
      fontSize: theme.typography.pxToRem(24),
    },
  },
  logoSVG: {
    marginLeft: theme.spacing(10),
    width: '135px',
    [theme.breakpoints.between('md', 'md')]: {
      margin: '26px 0',
      width: '120px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      margin: '25px 0',
      width: '120px',
    },
  },
  description: {
    lineHeight: '1.675',
    fontSize: theme.typography.pxToRem(24),
    marginTop: theme.spacing(6),
    [theme.breakpoints.between('md', 'md')]: {
      marginTop: theme.spacing(4),
      lineHeight: '1.675',
      fontFamily: `'Gotham Book', serif`,
      fontSize: theme.typography.pxToRem(15),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: theme.spacing(4),
      lineHeight: '1.675',
      fontSize: theme.typography.pxToRem(13),
      fontFamily: `'Gotham Book', serif`,
    },
  },
  btnTxt: {
    fontFamily: `'Gotham Book', serif`,
    fontSize: theme.typography.pxToRem(13.8),
    [theme.breakpoints.between('xs', 'md')]: {
      fontSize: theme.typography.pxToRem(10),
    },
    color: 'inherit',
  },
  btnLink: {
    textDecoration: 'none',
    textTransform: 'initial',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'none',
      borderBottomColor: theme.palette.text.primary,
    },
  },
  btnLinkPers: {
    textDecoration: 'none',
    textTransform: 'initial',
    width: '200px',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'none',
      borderBottomColor: theme.palette.text.primary,
    },
  },

  btnDiv: {
    marginTop: theme.spacing(6),
    [theme.breakpoints.between('md', 'md')]: {
      marginTop: theme.spacing(5),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: theme.spacing(4),
    },
  },
}))

interface IntroProps {
  title: string
  description: string
  showCircle?: boolean
  showBtn?: boolean
}

export const Intro: React.FC<IntroProps> = (props) => {
  const intl = useIntl()
  const { title, description, showCircle = true, showBtn = true } = props
  const theme = useTheme()
  const isSm = useMediaQuery(theme.breakpoints.down('sm'))
  const classes = useStyles()
  const scrollToEstimate = () => {
    if (isSm) window.location.href = `/${intl.locale}/sell`
    const estimateSection = document.getElementById('estimate-section')

    if (estimateSection) {
      estimateSection.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <>
      <Grid container sm={12} className={classes.root}>
        <Hidden only={['md', 'lg', 'xl']}>
          <img src={LogoSvg} alt="Kretz" className={classes.logoSVG} loading="lazy" />
        </Hidden>

        <Grid item lg={4} md={4} sm={12} className={classes.titleContainer}>
          <Box display="flex" flexDirection="column" className={classes.title}>
            <Box>
              {title.split('\n').map((s: string, index: number) => (
                <div key={index}>{s}</div>
              ))}
            </Box>
            {showBtn && (
              <Grid className={classes.btnDiv}>
                <CustomButton
                  width={isSm ? '100%' : '250px'}
                  isDarkBackground={true}
                  icon={() => <ArrowForwardIcon />}
                  displayIcon={true}
                  href={`/${intl.locale}/agency`}
                >
                  {intl.formatMessage({ id: 'new.Home_3' })}
                </CustomButton>
                <Grid style={{ marginTop: '20px' }}>
                  <CustomButton
                    width={isSm ? '100%' : '250px'}
                    // darkBoarder={true}
                    isWhiteBackground={true}
                    onClick={scrollToEstimate}
                  >
                    {intl.formatMessage({ id: 'new.Home_4' })}
                  </CustomButton>
                </Grid>
              </Grid>
            )}
          </Box>
        </Grid>

        <Grid item lg={8} md={8} sm={12}>
          <Hidden smDown>
            <img src={LogoSvg} alt="Kretz" className={classes.logoSVG} loading="lazy" />
          </Hidden>
          <Hidden smDown>
            <Box display="flex" flexDirection="column" className={classes.description}>
              {description.split('\n').map((s) => (
                <div key={s}>{s}</div>
              ))}
            </Box>
          </Hidden>
        </Grid>
      </Grid>
    </>
  )
}

export default Intro
